import React from 'react';

import './message.css';

import {IconAssets} from '../../utils/ImageAssets';
import { Crypt } from 'hybrid-crypto-js';
import moment from 'moment'

// Muestra el componente de mensaje
const Message = ({sender, message, idChat, type, time, timeRead, file, press, pressFile, typeFile, isSended, idSession}) => {
    const date = new Date();

    console.log('dateeee', file)
    console.log('timeeee', time)

    const decriptMessage = () => {
        let privateKey = localStorage.getItem('privatekey');
        let crypt = new Crypt();
        try {
            let messageDecripted = crypt.decrypt(privateKey, message);
            return messageDecripted.message;
        } catch (err) {
            console.log('catch decript')
        }
    }

    // Si el tipo de mensaje es eevento se muestra el boton con el que se inicia una videolllamada
    // Si el tipo de mensaje es distinto de evento se muestra cualquier otro tipo de emnesaje como puedan ser imagenes, videos y/o archivos
    return (
        type !== 'event' ?
            <div className={sender ? 'containerMessageSender' : 'containerMessageNoSender'}>
                <div className={sender ? 'containerTextSender' : 'containerTextNotSender'}>
                    {((file !== null && file !== undefined) && (file.includes('png') || file.includes('jpg') || file.includes('jpeg'))) &&
                        <img style={{maxWidth: 100, maxHeight: 200, marginBottom: 10, cursor: 'pointer'}} alt={''} src={`https://node.mybeatcoach.com/channels/${idChat}/${file}`} onClick={() => {
                            pressFile(`https://node.mybeatcoach.com/channels/${idChat}/${file}`);
                            typeFile('image');
                            isSended(true);
                        }}/>
                    }
                    {((file !== null && file !== undefined) && file.includes('mp4')) &&
                        <video style={{maxWidth: 200, maxHeight: 300, marginBottom: 10}} src={`https://node.mybeatcoach.com/channels/${idChat}/${file}`} controls/>
                    }
                    {((file !== null && file !== undefined) && file.includes('pdf')) &&
                        <div className={'containerFileMessageComponent'} onClick={() => {
                            pressFile(`https://node.mybeatcoach.com/channels/${idChat}/${file}`);
                            typeFile('document');
                            isSended(true);
                        }}>
                            <img style={{maxWidth: 100, maxHeight: 100, marginBottom: 10, marginRight: 7}} alt={''} src={IconAssets.file} controls/>
                            {/* Cuando el tipo de archivo es file mira el nombre del archivo y lo corta para mostrar el nombre original del archivo*/}
                            {file.split('_')[1]}
                        </div>
                    }
                    {decriptMessage()}
                </div>
                {String(moment(date).format('DD')) === String(moment(time).format('DD')) ?
                    <p className={'timeMessage'}>{moment(time).format('LT')} <br /> {timeRead ? ' read:' + moment(timeRead).format('LT') : '' }</p>
                    :
                    <p className={'timeMessage'}>{moment(time).format('DD/MM/YY - LT')} <br /> {timeRead ? 'read:' + moment(timeRead).format('DD/MM/YY - LT') : '' }</p>
                }
            </div>
            :
            (message === 'videocall_started' ?
                <div className={'containerButtonMessage'}>
                    <button className={'buttonMessage'} onClick={press}>
                    Haz click aqui para iniciar videollamada
                    </button>
                    <p className={'timeMessage'}>{time}</p>
                </div>
                :
                <div className={sender ? 'containerMessageSender' : 'containerMessageNoSender'}>
                    <div className={sender ? 'containerTextSender' : 'containerTextNotSender'}>La sesión de video se ha finalizado.</div>
                    <p className={'timeMessage'}>{time}</p>

                </div>
            )


    )
}

export default Message;
